<script lang="ts" setup>
const localePath = useLocalePath()
const switchLocalePath = useSwitchLocalePath()
</script>

<template>
  <header>
    <NuxtLink class="logo" :to="localePath('/')" title="Home">
      <img class="logo" alt="Silvabird logo" src="/img/logos/logo-with-text.svg" />
    </NuxtLink>
    <div class="right-side">
      <div class="locale-switch">
        <nuxt-link v-if="$i18n.locale !== 'en'" :to="switchLocalePath('en')"> EN </nuxt-link>
        <nuxt-link v-if="$i18n.locale !== 'nl'" :to="switchLocalePath('nl')"> NL </nuxt-link>
      </div>
      <AppButton :as-anchor="true" href="https://app.silvabird.com/"
        ><IconsAccountCircleIcon /> {{ $t('header.loginButton') }}</AppButton
      >
    </div>
  </header>
</template>

<style lang="scss" scoped>
header {
  height: 40px;
  padding: 20px 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: sticky;
  top: 0;
  background: var(--background-transparent);
  backdrop-filter: blur(10px);
  z-index: 2;

  .logo {
    height: 100%;
  }
}

.right-side {
  display: flex;
  align-items: center;
  gap: 30px;
}

.locale-switch {
  a {
    color: var(--text);
    text-decoration: none;
    font-weight: bold;
  }
}
</style>
