<script lang="ts" setup>
const localePath = useLocalePath()
const { locale, availableLocales } = useI18n()

const legalPaths = ['/legal/terms-of-service', '/legal/privacy-policy']

// FIXME: Only fetch for relevant locale, doesn't seem to be working due to a bug in nuxt content
// Need to figure it out or submit it together with a repoducable repo.
const { data: legalPagesByLocale } = await useAsyncData('legalPagesByLocale', async () =>
  Object.fromEntries(
    await Promise.all(
      availableLocales.map(async (locale) => [
        locale,
        await queryCollection('content')
          .select('path', 'title')
          .where(
            'path',
            'IN',
            legalPaths.map((path) => localePath(path, locale)),
          )
          .all(),
      ]),
    ),
  ),
)

const legalPages = computed(() => legalPagesByLocale.value[locale.value])
</script>

<template>
  <footer>
    <div class="inner">
      <div>
        <h4>{{ $t('footer.legal') }}</h4>
        <ul>
          <li v-for="page of legalPages" :key="page.path">
            <NuxtLink :to="page.path">{{ page.title }}</NuxtLink>
          </li>
        </ul>
      </div>
      <div>
        <p class="non-tracking-notice">
          <i18n-t keypath="footer.nonCookieMessage.noBanner">
            <template v-slot:noTracking>
              <b>{{ $t('footer.nonCookieMessage.noTracking') }}</b>
            </template>
          </i18n-t>
        </p>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
footer {
  margin-top: 100px;
  box-shadow: inset 0px 65px 65px 18px #5a638112;
  border-top: 1px solid var(--border-subtle);

  .inner {
    max-width: 1200px;
    margin: auto;
    padding: 0 40px;
    gap: 10px 40px;
    height: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
}

ul {
  list-style-type: none;
  padding: 0;
}

.non-tracking-notice {
  color: var(--text-subtle);
}
</style>
